<template>
  <div style="display: flex">
    <vue-select
      placeholder=""
      v-model="pays"
      :options="options"
      label-by="label"
      @selected="selected"
      @focus="focus"
      :close-on-select="true"
      class="vue-select-custom"
    >
      <template #label="{ selected }">
        <template v-if="selected">
          <div class="itm-mt-4">
            <span class="itm-ml-10"
              ><img width="16" height="16" :src="selected.image"
            /></span>
            <span class="itm-ml-10">{{ selected.nom }} </span>
          </div>
        </template>
        <template v-else>
          <div class="itm-mt-4">
            <span class="itm-ml-10"
              ><img width="16" height="16" :src="pays.image"
            /></span>
            <span class="itm-ml-10">{{ pays.nom }} </span>
          </div>
        </template>
      </template>
      <template #dropdown-item="{ option }">
        <div>
          <div class="itm-mt-4">
            <span class="itm-ml-10"
              ><img width="16" height="16" :src="option.image"
            /></span>
            <span class="itm-ml-10">{{ option.nom }} </span>
          </div>
        </div>
      </template>
    </vue-select>
  </div>
</template>

<script>
import { PAYS, FRANCE } from '../constantes';
const EVENT_EMIT_INPUT_PAYS = 'input-pays';
export default {
  name: 'InputPays',
  components: {},
  props: {
    options: { type: Array, default: PAYS }
  },
  data() {
    return {
      pays: FRANCE
    };
  },
  computed: {},
  methods: {
    selected() {
      this.$emit(EVENT_EMIT_INPUT_PAYS, this.pays);
    },
    focus() {}
  }
};
</script>

<style lang="scss" scoped>
.vue-select-custom {
  box-shadow: inset 0 -2px 0 0 #6a6a6a !important;
  height: 40px !important;
  width: 500px !important;
}

.itm-ml-10 {
  margin-left: 10px;
}

.itm-mt-4 {
  margin-top: 4px;
}
</style>

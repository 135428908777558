<template>
  <div>
    <div class="fr-col-2 fr-ml-6w"></div>
    <div class="container fr-col-12 fr-col-lg-9" v-if="!controlCreation">
      <div class="fr-grid-row">
        <div class="fr-col-12 entete">
          <div class="block-pcr-theme-page-title">
            <h1 class="title row mt-0 fs-b fs-2500 color-pr">
              Création de compte
            </h1>
          </div>
          <div class="champs-obligatoires">
            <div
              class="ui negative message"
              id="error-msg"
              hidden="hidden"
            ></div>

            * Champs obligatoires
          </div>
        </div>
      </div>
      <div class="ui container large aligned middle aligned segment-form">
        <form class="ui large form" id="register" name="register">
          <div class="name two fields">
            <img
              class="user icon-form"
              :class="
                !erreur.nom && !erreur.prenom && nom && prenom
                  ? 'logo-valide'
                  : ''
              "
              src="../assets/img/user-fill.svg"
              alt="icone en forme de silhouette humaine"
            />

            <div class="lastname required field">
              <label for="lastname" class="control-label">
                <span
                  :class="`${erreur.nom ? ' label-span-error' : ''}
              `"
                  >Nom</span
                >
                *
              </label>
              <input
                id="lastname"
                type="text"
                autocomplete="family-name"
                name="http://wso2.org/claims/lastname"
                :class="`lastname form-control${
                  erreur.nom ? ' input-error-border' : ''
                }
              `"
                placeholder="NOM"
                v-model="nom"
                aria-invalid="true"
                aria-describedby="error-lastname"
                aria-required="true"
                value=""
                @input="validateNom()"
              />
              <p
                class="message-error d-flex align-items-center"
                v-if="erreur.nom"
              >
                <i class="ri-error-warning-line" />
                {{ erreur.nom }}
              </p>
            </div>
            <div class="givenname required field">
              <label for="givenname" class="control-label">
                <span
                  :class="`${erreur.prenom ? ' label-span-error' : ''}
              `"
                  >Prénom</span
                >
                *</label
              >
              <input
                id="givenname"
                type="text"
                autocomplete="given-name"
                name="http://wso2.org/claims/givenname"
                :class="`givenname form-control${
                  erreur.prenom ? ' input-error-border' : ''
                }
              `"
                placeholder="Prenom"
                aria-invalid="true"
                aria-describedby="error-givenname"
                aria-required="true"
                value=""
                v-model="prenom"
                @input="validatePrenom()"
              />
              <p
                class="message-error d-flex align-items-center"
                v-if="erreur.prenom"
              >
                <i class="ri-error-warning-line" />
                {{ erreur.prenom }}
              </p>
            </div>
          </div>
          <div id="identifiants" class="mt-5">
            <div class="username field bloc-mail">
              <img
                class="mail icon-form"
                :class="!erreur.email && email ? 'logo-valide' : ''"
                src="../assets/img/mail-fill.svg"
                alt="icône d'un mail"
              />
              <div class="username required field">
                <label for="username" class="control-label">
                  <span
                    :class="`${erreur.email ? ' label-span-error' : ''}
              `"
                    >Identifiant (adresse courriel nominative)</span
                  >
                  *
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  autocomplete="email"
                  aria-invalid="true"
                  aria-describedby="error-username"
                  aria-required="true"
                  :class="`username form-control${
                    erreur.email ? ' input-error-border' : ''
                  }
              `"
                  value=""
                  placeholder="email@domaine.fr"
                  v-model="email"
                  @input="validateEmail()"
                />
                <p
                  class="message-error d-flex align-items-center"
                  v-if="erreur.email"
                >
                  <i class="ri-error-warning-line" />
                  {{ erreur.email }}
                </p>
              </div>
            </div>
            <div id="coordonnees">
              <div class="phone two fields fr-fieldset--inline bloc-tel">
                <img
                  class="phone icon-form"
                  :class="!erreur.mobile && mobile ? 'logo-valide' : ''"
                  src="../assets/img/phone-fill.svg"
                  alt="icone d'un téléphone "
                />
                <div class="mobile required field">
                  <div class="mobile required field">
                    <label for="mobile" class="control-label">
                      <span
                        :class="`${erreur.mobile ? ' label-span-error' : ''}
              `"
                        >Téléphone mobile</span
                      >
                      *<span class="sr-only">sans le premier zéro</span>
                    </label>
                    <span class="deque-tooltip-wrapper"
                      ><span
                        class="infobulle"
                        tabindex="0"
                        data-deque-tooltip="Téléphone mobile nécessaire pour réaliser la double authentification par SMS"
                        aria-label="Téléphone mobile nécessaire pour réaliser la double authentification par SMS"
                        style="position: absolute !important"
                        aria-describedby="g3bca0ab1-5838-3f57-c752-d25fde4b2d4f"
                      >
                      </span
                      ><span
                        role="tooltip"
                        id="g3bca0ab1-5838-3f57-c752-d25fde4b2d4f"
                        class="deque-tooltip deque-hidden"
                        aria-hidden="true"
                        ><Info
                          href="#"
                          data="Téléphone mobile
                      nécessaire pour réaliser la double authentification par
                      SMS"
                          cssClass="fr-fi-information-line ml-3 info a-info" /><span
                          aria-label="Tooltip :"
                        ></span></span
                    ></span>
                  </div>

                  <div class="indicatif">
                    <div
                      class="iti iti--allow-dropdown iti--separate-dial-code"
                    >
                      <div class="iti__flag-container">
                        <v-select
                          class="pays-select mt-2"
                          :options="payslist"
                          :searchable="false"
                          label="indicatif"
                          id="indicatif"
                          name="indicatif"
                          aria-label="indicatif"
                          v-model="indicatif"
                        >
                          <template v-slot:option="item">
                            <img
                              :src="item.image"
                              width="25"
                              height="25"
                              class="mr-2 flags"
                            />{{ item.nom }}
                            <span class="text-indicatif">{{
                              item.indicatif
                            }}</span>
                          </template>
                          <template #selected-option="{ image, indicatif }">
                            <div>
                              <img
                                :src="image"
                                width="20"
                                height="20"
                                class="mr-2 mb-1"
                              />
                              {{ indicatif }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <InputTelephone
                        ref="inputTelMobileEtape1"
                        verifierAncienMobile="false"
                        @input-tel-validation="getValidatedNumMobile"
                        type="MOBILE"
                        obligatoire="true"
                      />
                    </div>
                    <input
                      id="mobileform"
                      type="hidden"
                      name="http://wso2.org/claims/mobile"
                      value=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="passwordfield two fields">
              <img
                class="key icon-form"
                :class="
                  !erreur.newMdp1 && !erreur.newMdp2 && newMdp1 && newMdp2
                    ? 'logo-valide'
                    : ''
                "
                src="../assets/img/key-fill.svg"
                alt="icone d'une clée"
              />

              <div class="password required field form-newmdp">
                <label for="password" class="control-label">
                  <span
                    :class="`${erreur.newMdp1 ? ' label-span-error' : ''}
              `"
                    >Création du mot de passe</span
                  >
                  *
                </label>

                <div style="max-height: 46px">
                  <input
                    id="password"
                    name="password"
                    autocomplete="new-password"
                    :class="`password form-control${
                      erreur.newMdp1 ? ' input-error-border' : ''
                    }
              `"
                    aria-invalid="true"
                    aria-describedby="error-password"
                    aria-required="true"
                    :type="newPasswordFielType1"
                    v-model="newMdp1"
                    @input="validateMdp()"
                    @focus="onInputFocus('input-eye', '20rem')"
                    @blur="onInputBlur('input-eye', '22rem')"
                  />
                </div>
                <span
                  v-show="showNewMdp1"
                  class="ri-eye-off-line input-eye"
                  @click="showNewPassword()"
                  title="Cacher le mot de passe"
                />
                <span
                  v-show="!showNewMdp1"
                  class="ri-eye-line input-eye"
                  @click="showNewPassword()"
                  title="Afficher le mot de passe"
                ></span>
                <p
                  class="message-error d-flex align-items-center"
                  v-if="erreur.newMdp1"
                >
                  <i class="ri-error-warning-line" />
                  {{ erreur.newMdp1 }}
                </p>
                <div
                  class="error-message"
                  id="error-password"
                  hidden="hidden"
                ></div>

                <div class="form-controlemdp">
                  <p class="text-info">
                    Le mot de passe doit répondre aux exigences suivantes :
                  </p>
                  <p class="text-label">
                    <span
                      v-bind:class="{
                        'ri-check-line': controleMdp.tailleMdp,
                        'ri-close-line': !controleMdp.tailleMdp
                      }"
                    />
                    être compris entre 12 et 20 caractères
                  </p>
                  <p class="text-label">
                    <span
                      v-bind:class="{
                        'ri-check-line': controleMdp.minuscule,
                        'ri-close-line': !controleMdp.minuscule
                      }"
                    />
                    contenir 1 minuscule hors caractères accentués
                  </p>
                  <p class="text-label">
                    <span
                      v-bind:class="{
                        'ri-check-line': controleMdp.majuscule,
                        'ri-close-line': !controleMdp.majuscule
                      }"
                    />
                    contenir 1 majuscule hors caractères accentués
                  </p>
                  <p class="text-label">
                    <span
                      v-bind:class="{
                        'ri-check-line': controleMdp.chiffre,
                        'ri-close-line': !controleMdp.chiffre
                      }"
                    />
                    contenir 1 chiffre
                  </p>
                  <p class="text-label">
                    <span
                      v-bind:class="{
                        'ri-check-line': controleMdp.special,
                        'ri-close-line': !controleMdp.special
                      }"
                    />
                    contenir 1 caractère spécial parmi la liste
                    <span class="sr-only"
                      >Tilde, point d'exclamation, arobase, dièse, dollar,
                      pourcentage, accent circonflexe, et commercial,
                      astérisque, tiret du 8, moins, plus, égal, trait vertical,
                      parenthèse ouvrante, parenthèse fermante, accolade
                      ouvrante, accolade fermante, crochet ouvrant, crochet
                      fermant, deux point, point virgule, virgule, point, point
                      d'intérogation</span
                    >
                    <span aria-hidden="true">
                      (~!@#$%^&*_-+=|() {} [ ]:;,.?)</span
                    >
                  </p>
                  <p class="text-label">
                    <span
                      v-bind:class="{
                        'ri-check-line': controleMdp.memeMdp,
                        'ri-close-line': !controleMdp.memeMdp
                      }"
                    />
                    être confirmé une seconde fois
                  </p>
                </div>
              </div>
              <div class="password2 required field">
                <label for="password2" class="control-label">
                  <span
                    :class="`${erreur.newMdp2 ? ' label-span-error' : ''}
              `"
                    >Confirmation du mot de passe</span
                  >
                  *
                </label>
                <div class="d-flex align-items-center">
                  <input
                    id="password2"
                    name="password2"
                    autocomplete="off"
                    :class="`password form-control${
                      erreur.newMdp2 ? ' input-error-border' : ''
                    }
              `"
                    data-match="reg-password"
                    aria-invalid="true"
                    aria-describedby="error-password2"
                    aria-required="true"
                    :type="newPasswordFielType2"
                    v-model="newMdp2"
                    @input="validateMdp()"
                    @focus="onInputFocus('input-eye-2', '-4rem')"
                    @blur="onInputBlur('input-eye-2', '-2rem')"
                  />
                  <span
                    v-show="showNewMdp2"
                    class="ri-eye-off-line input-eye-2"
                    @click="showNewPassword2()"
                    title="Cacher le mot de passe"
                  />
                  <span
                    v-show="!showNewMdp2"
                    class="ri-eye-line input-eye-2"
                    @click="showNewPassword2()"
                    title="Afficher le mot de passe"
                  ></span>
                </div>
                <p
                  class="message-error d-flex align-items-center"
                  v-if="erreur.newMdp2"
                >
                  <i class="ri-error-warning-line" />
                  {{ erreur.newMdp2 }}
                </p>
              </div>
            </div>
          </div>
          <div id="coordonnees" v-show="coordonneeStep">
            <div class="text-left etape-precedente mb-3 mt-3">
              <a
                @click="etapePrecedente('identifiant')"
                class="fr-link p-0 color-pr fs-875 link-precedent soulignement"
                title="Étape précédente"
                ><i class="ri-arrow-left-line"></i>Étape précédente</a
              >
            </div>
            <div class="name two fields">
              <img
                class="user icon-form"
                :class="
                  !erreur.nom && !erreur.prenom && nom && prenom
                    ? 'logo-valide'
                    : ''
                "
                src="../assets/img/user-fill.svg"
                alt="icone en forme de silhouette humaine"
              />

              <div class="lastname required field">
                <label for="lastname" class="control-label">
                  <span
                    :class="`${erreur.nom ? ' label-span-error' : ''}
              `"
                    >Nom</span
                  >
                  *
                </label>
                <input
                  id="lastname"
                  type="text"
                  autocomplete="family-name"
                  name="http://wso2.org/claims/lastname"
                  :class="`lastname form-control${
                    erreur.nom ? ' input-error-border' : ''
                  }
              `"
                  placeholder="NOM"
                  v-model="nom"
                  aria-invalid="true"
                  aria-describedby="error-lastname"
                  aria-required="true"
                  value=""
                  @input="validateNom()"
                />
                <p
                  class="message-error d-flex align-items-center"
                  v-if="erreur.nom"
                >
                  <i class="ri-error-warning-line" />
                  {{ erreur.nom }}
                </p>
              </div>
              <div class="givenname required field">
                <label for="givenname" class="control-label">
                  <span
                    :class="`${erreur.prenom ? ' label-span-error' : ''}
              `"
                    >Prénom</span
                  >
                  *</label
                >
                <input
                  id="givenname"
                  type="text"
                  autocomplete="given-name"
                  name="http://wso2.org/claims/givenname"
                  :class="`givenname form-control${
                    erreur.prenom ? ' input-error-border' : ''
                  }
              `"
                  placeholder="Prenom"
                  aria-invalid="true"
                  aria-describedby="error-givenname"
                  aria-required="true"
                  value=""
                  v-model="prenom"
                  @input="validatePrenom()"
                />
                <p
                  class="message-error d-flex align-items-center"
                  v-if="erreur.prenom"
                >
                  <i class="ri-error-warning-line" />
                  {{ erreur.prenom }}
                </p>
              </div>
            </div>
            <div class="phone two fields fr-fieldset--inline bloc-tel">
              <img
                class="phone icon-form"
                :class="!erreur.mobile && mobile ? 'logo-valide' : ''"
                src="../assets/img/phone-fill.svg"
                alt="icone d'un téléphone "
              />
              <div class="mobile required field">
                <div class="mobile required field">
                  <label for="mobile" class="control-label">
                    <span
                      :class="`${erreur.mobile ? ' label-span-error' : ''}
              `"
                      >Téléphone mobile</span
                    >
                    *<span class="sr-only">sans le premier zéro</span>
                  </label>
                  <span class="deque-tooltip-wrapper"
                    ><span
                      class="infobulle"
                      tabindex="0"
                      data-deque-tooltip="Téléphone mobile nécessaire pour réaliser la double authentification par SMS"
                      aria-label="Téléphone mobile nécessaire pour réaliser la double authentification par SMS"
                      style="position: absolute !important"
                      aria-describedby="g3bca0ab1-5838-3f57-c752-d25fde4b2d4f"
                    >
                    </span
                    ><span
                      role="tooltip"
                      id="g3bca0ab1-5838-3f57-c752-d25fde4b2d4f"
                      class="deque-tooltip deque-hidden"
                      aria-hidden="true"
                      ><Info
                        href="#"
                        data="Téléphone mobile
                      nécessaire pour réaliser la double authentification par
                      SMS"
                        cssClass="fr-fi-information-line ml-3 info a-info" /><span
                        aria-label="Tooltip :"
                      ></span></span
                  ></span>
                </div>

                <div class="">
                  <InputTelephone
                    ref="inputTelMobileEtape2"
                    verifierAncienMobile="false"
                    @input-tel-validation="getValidatedNumMobile"
                    type="MOBILE"
                    obligatoire="true"
                  />
                  <input
                    id="mobileform"
                    type="hidden"
                    name="http://wso2.org/claims/mobile"
                    value=""
                  />
                </div>
              </div>
              <div class="">
                <label for="telephone" class="control-label">
                  <span
                    :class="`${erreur.telephone ? ' label-span-error' : ''}
              `"
                    >Téléphone fixe</span
                  >
                </label>

                <div class="">
                  <InputTelephone
                    verifierAncienMobile="false"
                    @input-tel-validation="getValidatedNumFixe"
                    type="FIXE"
                  />
                  <input
                    id="telephoneform"
                    name="http://wso2.org/claims/telephone"
                    type="hidden"
                    value=""
                  />
                </div>
              </div>
            </div>
            <div class="fields fr-fieldset--inline">
              <img
                class="user icon-form"
                :class="
                  !erreur.nom && !erreur.prenom && nom && prenom
                    ? 'logo-valide'
                    : ''
                "
                src="../assets/img/map-pin-fill.svg"
                alt="icone d'un repere de map"
              />
              <div class="adresse-div">
                <div class="row">
                  <div class="col-sm-12">
                    <label for="adresse-input" class="control-label"
                      ><span
                        :class="`${erreur.adresse ? ' label-span-error' : ''}
              `"
                        >Adresse</span
                      >
                      *</label
                    >
                    <input
                      :class="`fr-input${erreur.adresse ? ' input-error' : ''}
              `"
                      placeholder="N° voie - Type de voie - Libellé de voie"
                      id="adresse-input"
                      name="adresse-input"
                      v-model="adresse"
                      maxlength="80"
                      @keyup="checkCaractereNonImprimable()"
                    />
                    <p
                      class="message-error d-flex align-items-center"
                      v-if="erreur.adresse"
                    >
                      <i class="ri-error-warning-line" />
                      {{ erreur.adresse }}
                    </p>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-sm-6">
                    <label for="complement-1-input" class="control-label"
                      >Complément d'adresse 1</label
                    >
                    <input
                      class="fr-input"
                      placeholder="Complément 1"
                      id="search-complement-1"
                      name="search-complement-1"
                      v-model="complement1"
                      maxlength="80"
                      @keyup="checkCaractereNonImprimable()"
                    />
                  </div>
                  <div class="col-sm-6">
                    <label for="complement-2-input" class="control-label"
                      >Complément d'adresse 2</label
                    >
                    <input
                      class="fr-input"
                      placeholder="Complément 2"
                      id="complement-2-input"
                      name="complement-2-input"
                      v-model="complement2"
                      maxlength="80"
                      @keyup="checkCaractereNonImprimable()"
                    />
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-sm-2">
                    <label for="code-postal-input" class="control-label">
                      <span
                        :class="`${erreur.codePostal ? ' label-span-error' : ''}
              `"
                      >
                        Code postal</span
                      >
                      *</label
                    >
                    <input
                      :class="`fr-input${
                        erreur.codePostal ? ' input-error' : ''
                      }
              `"
                      placeholder="00000"
                      id="code-postal-input"
                      name="code-postal-input"
                      v-model="codePostal"
                      maxlength="5"
                      v-maska
                      data-maska="#####"
                      @keyup="checkCaractereNonImprimable()"
                    />
                    <p
                      class="message-error d-flex align-items-center"
                      v-if="erreur.codePostal"
                    >
                      <i class="ri-error-warning-line" />
                      {{ erreur.codePostal }}
                    </p>
                  </div>
                  <div class="col-sm-6 ville-mobile">
                    <label for="ville-input" class="control-label">
                      <span
                        :class="`${erreur.ville ? ' label-span-error' : ''}
              `"
                      >
                        Ville</span
                      >
                      *</label
                    >
                    <input
                      :class="`fr-input${erreur.ville ? ' input-error' : ''}
              `"
                      placeholder="Nom ville"
                      id="ville-input"
                      name="ville-input"
                      v-model="ville"
                      maxlength="40"
                      @keyup="checkCaractereNonImprimable()"
                    />
                    <p
                      class="message-error d-flex align-items-center"
                      v-if="erreur.ville"
                    >
                      <i class="ri-error-warning-line" />
                      {{ erreur.ville }}
                    </p>
                  </div>
                  <div class="col-sm-4 pays-mobile">
                    <label for="pays-input" class="control-label">
                      Pays *</label
                    >
                    <InputPays
                      :options="adressePayslist"
                      @input-pays="getSelectedPays"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="align-center buttons">
              <button
                class="pr-btn-annuler"
                data-fr-opened="false"
                aria-controls="cancel-popup"
                type="button"
                @click="annuler()"
              >
                Annuler
              </button>
              <button
                id="registrationSubmit"
                class="pr-btn-valider"
                @click="etapeSuivante('status')"
                type="button"
              >
                Étape suivante
              </button>
            </div>
          </div>
          <div id="status" v-if="statusStep">
            <div class="text-left etape-precedente mb-3 mt-3">
              <a
                @click="etapePrecedente('coordonnee')"
                class="fr-link p-0 color-pr fs-875 link-precedent soulignement"
                title="Étape précédente"
                ><i class="ri-arrow-left-line"></i>Étape précédente</a
              >
            </div>
          </div>
          <div id="status">
            <div class="work bloc fields">
              <img
                class="case icon-form tier-icon"
                :class="!erreur.tier && tier ? 'logo-valide' : ''"
                src="../assets/img/briefcase-fill.svg"
                alt="icone de malette"
              />
              <div class="switch-bloc">
                <div class="switch-claim">
                  <div role="radiogroup">
                    <fieldset
                      class="tier required field fr-fieldset fr-fieldset--inline"
                    >
                      <legend>
                        <div class="tier required field">
                          <label class="control-label">
                            <span
                              :class="`${erreur.tier ? ' label-span-error' : ''}
              `"
                              >Tiers déclarant</span
                            >
                            *</label
                          >
                          <span class="deque-tooltip-wrapper"
                            ><Info
                              href="#"
                              data="Dans le cadre de la mise en place du Portailpro, il est prévu qu’une entreprise puisse confier à un tiers (expert-comptable, représentant en douane enregistré, centre de gestion...) le soin de réaliser ses formalités fiscales, sociales, douanières ; ses conditions d’utilisation s’appliquent alors à ce tiers, qui doit avoir reçu mandat de la part de l’entreprise pour accomplir cette formalité."
                              cssClass="fr-fi-information-line ml-3 info a-info"
                              aria-label="Dans le cadre de la mise en place du Portailpro, il est prévu qu’une entreprise puisse confier à un tiers (expert-comptable, représentant en douane enregistré, centre de gestion...) le soin de réaliser ses formalités fiscales, sociales, douanières ; ses conditions d’utilisation s’appliquent alors à ce tiers, qui doit avoir reçu mandat de la part de l’entreprise pour accomplir cette formalité."
                          /></span>
                        </div>
                      </legend>

                      <div class="fr-fieldset__content">
                        <div class="fr-radio-group">
                          <input
                            type="radio"
                            class="radio"
                            id="tiers-true"
                            name="http://wso2.org/claims/tiers_declarant"
                            aria-invalid="true"
                            aria-describedby="error-tier"
                            aria-required="true"
                            v-model="tier"
                            value="oui"
                          />
                          <label
                            :class="{
                              'fr-label-checked': tier === 'oui',
                              'fr-label': tier !== 'oui'
                            }"
                            class="fr-label"
                            for="tiers-true"
                            >Oui
                          </label>
                        </div>
                        <div class="fr-radio-group">
                          <input
                            type="radio"
                            id="tiers-false"
                            name="http://wso2.org/claims/tiers_declarant"
                            aria-invalid="true"
                            aria-describedby="error-tier"
                            class="radio"
                            aria-required="true"
                            v-model="tier"
                            value="non"
                          />
                          <label
                            :class="{
                              'fr-label-checked': tier === 'non',
                              'fr-label': tier !== 'non'
                            }"
                            class="fr-label"
                            for="tiers-false"
                            >Non
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <p
                  class="message-error d-flex align-items-center"
                  v-if="erreur.tier"
                >
                  <i class="ri-error-warning-line" />
                  {{ erreur.tier }}
                </p>
              </div>
            </div>
            <div class="username field bloc-mail">
              <img
                class="mail icon-form"
                :class="!erreur.captcha && captchaReponse ? ' logo-valide' : ''"
                src="../assets/img/lock-password-fill.svg"
                alt="icone de cadenas fermé"
              />
              <div class="username required field mb-5">
                <label for="captcha" class="control-label">
                  <span
                    :class="`${erreur.captcha ? ' label-span-error' : ''}
              `"
                    >Captcha</span
                  >
                  *</label
                >
                <input
                  id="captcha"
                  name="captcha"
                  type="text"
                  aria-invalid="true"
                  aria-describedby="error-captcha"
                  aria-required="true"
                  :class="`username form-control${
                    erreur.captcha ? ' input-error-border' : ''
                  }
              `"
                  value=""
                  v-model="captchaReponse"
                />
                <div class="d-flex flex-row align-items-center">
                  <img
                    for="captcha-input"
                    :src="'data:image/png;base64,' + captcha.captchaImg"
                    width="275"
                    height="50"
                    class="mr-2 captcha-image"
                    alt="image d'un captcha"
                  />
                  <img
                    src="../assets/img/speaker.svg"
                    width="40"
                    height="40"
                    alt="icone haut parleur"
                    @click="playCaptchaAudio()"
                    class="captcha-btn"
                  />
                  <img
                    src="../assets/img/refresh-captcha.svg"
                    width="30"
                    height="30"
                    class="ml-2 captcha-btn"
                    alt="icone d'une fléche qui forme un cercle"
                    @click="getCaptchaImage()"
                  />
                </div>
                <p
                  class="message-error d-flex align-items-center"
                  v-if="erreur.captcha"
                >
                  <i class="ri-error-warning-line" />
                  {{ erreur.captcha }}
                </p>
              </div>
            </div>
            <div class="field">
              <div class="terms-privacy">
                <!--Terms/Privacy Policy-->
                <div class="required field">
                  <div class="ui">
                    <div class="fr-checkbox-group">
                      <input
                        type="checkbox"
                        class="fr-toggle__input"
                        id="termsCheckbox"
                        name="termsCheckbox"
                        value="true"
                        v-model="termsCheckbox"
                      />
                      <label class="fr-label termsCheckbox" for="termsCheckbox">
                        <span
                          :class="`${
                            erreur.termsCheckbox ? ' label-span-error' : ''
                          }
              `"
                          >J’ai lu et j’accepte</span
                        >

                        <router-link
                          :to="{ name: 'Conditions Générales d\'Utilisation' }"
                          target="_blank"
                          style="
                            color: var(--bf500);
                            text-decoration: underline;
                          "
                        >
                          les conditions générales d’utilisation</router-link
                        >
                        *</label
                      >
                    </div>
                  </div>

                  <br />
                  <p
                    class="message-error d-flex align-items-center"
                    v-if="
                      erreur.termsCheckbox && erreur.termsCheckbox.length !== 0
                    "
                  >
                    <i class="ri-error-warning-line" />
                    {{ erreur.termsCheckbox }}
                  </p>
                </div>
              </div>
            </div>
            <div class="align-center buttons">
              <button
                class="pr-btn-annuler"
                data-fr-opened="false"
                aria-controls="cancel-popup"
                type="button"
                @click="annuler()"
              >
                Annuler
              </button>
              <button
                id="registrationSubmit"
                class="pr-btn-valider"
                @click="
                  creationCompte();
                  tagCreationCompte();
                "
                type="button"
              >
                Valider l'inscription
              </button>
            </div>
          </div>
          <div>
            <div class="field">
              <input
                id="isSelfRegistrationWithVerification"
                type="hidden"
                name="isSelfRegistrationWithVerification"
                value="true"
              />
              <input
                id="tenantDomain"
                name="tenantDomain"
                type="hidden"
                value="carbon.super"
              />
            </div>
            <input
              type="hidden"
              name="http://wso2.org/claims/identity/phoneVerified"
              value="false"
            />
            <input
              type="hidden"
              name="http://wso2.org/claims/rattachement.pending"
              :value="rattachementPendingParam"
            />

            <div class="field">
              <input
                type="hidden"
                name="sessionDataKey"
                value="ee12df15-cd37-483f-8dbc-9e436b9ba563"
              />
            </div>
          </div>
          <div class="message-personal-data" v-if="statusStep">
            <p>
              <strong>
                Information sur le traitement des données à caractère personnel
              </strong>
              <br />
              La Mission France Recouvrement, en tant que responsable de
              traitement, traite vos données à caractère personnel à des fins de
              création et gestion de votre compte sur portailpro.gouv ainsi que
              des habilitations liées à celui-ci. Vous disposez d’un droit
              d’accès, de rectification, d’effacement des données vous
              concernant ainsi que d’un droit d’opposition et à la limitation du
              traitement. Pour en savoir plus sur le traitement de vos données,
              vous pouvez vous reporter à la page «&nbsp;<router-link
                :to="{ name: 'Données personnelles et cookies' }"
                >Données personnelles et cookies</router-link
              >&nbsp;» présent sur le portail en pied de page.
            </p>
          </div>
        </form>
      </div>
    </div>
    <div
      class="fr-col-12 fr-col-lg-9"
      v-if="controlCreation"
      id="messageCompteCree"
    >
      <div class="fr-grid-row">
        <div class="fr-col-12 entete">
          <div class="block-pcr-theme-page-title">
            <h1 class="title row mt-0 fs-b fs-2500 color-pr">
              Création de compte
            </h1>
          </div>
          <div class="row container">
            <div class="col-sm-4">
              <img
                src="../assets/img/mail.svg"
                class="mt-4 img-mobile"
                aria-hidden="true"
                alt="icone d'un mail"
              />
            </div>
            <div class="col-sm-8 mt-5">
              <span class="title-bloc"
                >Nous vous avons envoyé un courriel de confirmation à<br />
                <strong
                  >« {{ email }} »<br />
                  Merci de le consulter afin de finaliser votre inscription.
                </strong></span
              ><br />

              <div class="form-button mt-5">
                <button class="col pr-btn-valider" @click="fermer()">
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
const CHAMP_OBLIGATOIRE = 'Ce champ est obligatoire.';
import Info from '../shared/Info';
import { EVENT_ACCES_RAPIDE, PAYS, FRANCE } from '../constantes';
import { sendTag } from '../utils/methodsUtils';
import InputTelephone from '../components/InputTelephone.vue';
import InputPays from '../components/InputPays.vue';

export default {
  components: {
    Info,
    InputTelephone,
    InputPays
  },
  data() {
    return {
      pays: FRANCE,
      validatedNumMobile: { isValid: false, value: '', message: '' },
      rattachementPendingParam: '',
      controlCreation: false,
      erreur: {
        nom: '',
        prenom: '',
        email: '',
        indicatif: '',
        mobile: '',
        tier: '',
        newMdp1: '',
        newMdp2: '',
        termsCheckbox: '',
        captcha: ''
      },
      nom: '',
      prenom: '',
      email: '',
      indicatif: {
        nom: 'France',
        indicatif: '+33',
        image: require('@/assets/img/france.svg')
      },
      mobile: '',
      tier: '',
      termsCheckbox: '',
      controleMdp: {
        minuscule: false,
        majuscule: false,
        chiffre: false,
        special: false,
        memeMdp: false,
        tailleMdp: false
      },
      newPasswordFielType1: 'password',
      newPasswordFielType2: 'password',
      newMdp1: undefined,
      newMdp2: undefined,
      showNewMdp1: false,
      showNewMdp2: false,
      regexNomPrenom: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,

      captcha: {
        captchaId: '',
        captchaImg: ''
      },
      captchaReponse: '',
      etapeNumber: 1,
      etapeName: 'Identifiants de connexion',
      etapeDetail: 'Coordonnées',
      identifiantStep: true,
      coordonneeStep: false,
      statusStep: false,
      identifiantFilStep: true,
      coordonneeFilStep: false,
      statusFilStep: false,
      adressePayslist: PAYS.filter(
        (p) =>
          p.indicatif === '33' || p.indicatif === '376' || p.indicatif === '377'
      )
    };
  },
  methods: {
    getSelectedPays(payload) {
      this.pays = payload;
    },
    getValidatedNumMobile(payload) {
      this.validatedNumMobile = payload;
      this.mobile = payload.value;
      if (!payload.value.length) {
        // on laisse le message obligatoire de l'écran de création de compte
        this.erreur.mobile = false;
        this.$refs.inputTelMobileEtape1.forceValid(true);
        this.$refs.inputTelMobileEtape2.forceValid(true);
      } else {
        this.erreur.mobile = !payload.isValid;
      }
    },
    tagCreationCompte() {
      sendTag('Creation_compte', 'finalisation', '', '');
    },
    fermer() {
      this.$router.push({ name: 'Mon compte' }).catch(() => {});
    },
    validateMdp() {
      this.validatenewMdp1();
      this.validatenewMdp2();
      this.erreur.newMdp1 = '';

      if (this.newMdp1 && this.newMdp2 && this.newMdp1 === this.newMdp2) {
        this.controleMdp.memeMdp = true;
      } else {
        this.controleMdp.memeMdp = false;
        this.msgErreur = 'La confirmation du mot de passe ne correspond pas.';
      }
      this.controleMdp.minuscule = /[a-z]/.test(this.newMdp1);
      this.controleMdp.majuscule = /[A-Z]/.test(this.newMdp1);
      this.controleMdp.chiffre = /[0-9]/.test(this.newMdp1);
      // eslint-disable-next-line no-useless-escape
      this.controleMdp.special = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\{\}\[\]\:\;\|\~\_\-\,\?]/.test(
        this.newMdp1
      );
      this.controleMdp.tailleMdp =
        this.newMdp1 && 12 <= this.newMdp1.length && this.newMdp1.length <= 20;
      // eslint-disable-next-line no-useless-escape
      const allConditions = /^[a-zA-Z0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\{\}\[\]\:\;\|\~\_\-\,\?]+$/.test(
        this.newMdp1
      );

      if (
        this.controleMdp.minuscule &&
        this.controleMdp.majuscule &&
        this.controleMdp.chiffre &&
        this.controleMdp.special &&
        this.controleMdp.tailleMdp &&
        this.controleMdp.memeMdp &&
        allConditions
      ) {
        return true;
      } else if (
        this.controleMdp.minuscule &&
        this.controleMdp.majuscule &&
        this.controleMdp.chiffre &&
        this.controleMdp.special &&
        this.controleMdp.tailleMdp &&
        !allConditions
      ) {
        this.erreur.newMdp1 =
          '"Un caractère non autorisé est utilisé. Veuillez vérifier votre saisie en respectant la liste ci-dessous."';
        this.controleMdp.special = false;
      } else if (
        this.controleMdp.minuscule &&
        this.controleMdp.majuscule &&
        this.controleMdp.chiffre &&
        this.controleMdp.special &&
        this.controleMdp.tailleMdp &&
        !this.controleMdp.memeMdp &&
        allConditions
      ) {
        this.erreur.newMdp1 = '';
        this.erreur.newMdp2 =
          'Ce champ doit être identique au mot de passe saisi';
      } else {
        this.erreur.newMdp1 =
          'Le mot de de passe n’est pas au bon format. Veuillez vérifier votre saisie.';
      }

      return false;
    },
    validateNom() {
      this.erreur.nom = '';
      if (this.nom && this.nom.length !== 0) {
        if (!this.regexNomPrenom.test(this.nom)) {
          this.erreur.nom = "Le nom n'est pas au bon format.";
          return false;
        } else {
          return true;
        }
      } else {
        this.erreur.nom = CHAMP_OBLIGATOIRE;
        return false;
      }
    },
    validatePrenom() {
      this.erreur.prenom = '';
      if (this.prenom && this.prenom.length !== 0) {
        if (!this.regexNomPrenom.test(this.prenom)) {
          this.erreur.prenom = "Le prénom n'est pas au bon format.";
          return false;
        } else {
          return true;
        }
      } else {
        this.erreur.prenom = CHAMP_OBLIGATOIRE;
        return false;
      }
    },
    validateMobile() {
      if (!this.validatedNumMobile.value.length) {
        this.erreur.mobile = CHAMP_OBLIGATOIRE;
        this.$refs.inputTelMobileEtape1.forceErrorStyle(CHAMP_OBLIGATOIRE);
        this.$refs.inputTelMobileEtape2.forceErrorStyle(CHAMP_OBLIGATOIRE);
        return false;
      }
      if (this.validatedNumMobile.isValid) {
        return true;
      } else {
        if (!this.validatedNumMobile.value.length) {
          this.$refs.inputTelMobileEtape1.forceErrorStyle(CHAMP_OBLIGATOIRE);
          this.$refs.inputTelMobileEtape2.forceErrorStyle(CHAMP_OBLIGATOIRE);
        }
        this.erreur.mobile = CHAMP_OBLIGATOIRE;
        return false;
      }
    },
    validateTier() {
      this.erreur.tier = '';
      if (this.tier && (this.tier === 'oui' || this.tier === 'non')) {
        return true;
      } else {
        this.erreur.tier = CHAMP_OBLIGATOIRE;
        return false;
      }
    },
    validatenewMdp1() {
      this.erreur.newMdp1 = '';
      if (!this.newMdp1 || this.newMdp1.length === 0) {
        this.erreur.newMdp1 = CHAMP_OBLIGATOIRE;
      }
    },
    validatenewMdp2() {
      this.erreur.newMdp2 = '';
      if (!this.newMdp2 || this.newMdp2.length === 0) {
        this.erreur.newMdp2 = CHAMP_OBLIGATOIRE;
      }
    },
    validateTermsCheckbox() {
      this.erreur.termsCheckbox = '';
      if (this.termsCheckbox) {
        return true;
      } else {
        this.erreur.termsCheckbox = CHAMP_OBLIGATOIRE;
        return false;
      }
    },
    validateEmail() {
      this.erreur.email = '';
      if (this.email && this.email.length !== 0) {
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.email
          )
        ) {
          return true;
        } else {
          this.erreur.email =
            "L'adresse courriel n'est pas au bon format : exemple@domaine.com";
          return false;
        }
      } else {
        this.erreur.email = CHAMP_OBLIGATOIRE;
        return false;
      }
    },

    showNewPassword() {
      this.showNewMdp1 = !this.showNewMdp1;
      this.newPasswordFielType1 =
        this.newPasswordFielType1 === 'password' ? 'text' : 'password';
    },

    showNewPassword2() {
      this.showNewMdp2 = !this.showNewMdp2;
      this.newPasswordFielType2 =
        this.newPasswordFielType2 === 'password' ? 'text' : 'password';
    },

    getCaptchaImage() {
      this.$captchaService
        .getCaptchaImage()
        .then((result) => {
          this.captcha = result.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    playCaptchaAudio() {
      this.$captchaService.playCaptchaAudio(this.captcha.captchaId);
    },

    async validateCaptcha() {
      let control = false;
      if (this.captchaReponse && this.captchaReponse.trim()) {
        await this.$captchaService
          .validateCaptcha(this.captcha.captchaId, this.captchaReponse)
          .then((result) => {
            if (!result.data) {
              this.erreur.captcha = 'Le captcha est invalide.';
              this.getCaptchaImage();
            }
            control = result.data;
          })
          .catch((err) => {
            console.error(err);
            control = false;
          });
      } else {
        this.erreur.captcha = CHAMP_OBLIGATOIRE;
        control = false;
      }
      return control;
    },

    async creationCompte() {
      const email = this.validateEmail();
      const mdp = this.validateMdp();
      const nom = this.validateNom();
      const prenom = this.validatePrenom();
      const mobile = this.validateMobile();

      const tier = this.validateTier();
      const captcha = await this.validateCaptcha();
      const termsCheckbox = this.validateTermsCheckbox();

      if (
        mdp &&
        nom &&
        prenom &&
        email &&
        mobile &&
        tier &&
        captcha &&
        termsCheckbox
      ) {
        this.showPopupCreation();
      } else {
        if (!email || !mdp) {
          this.etapePrecedente('identifiant');
        } else if (!nom || !prenom || !mobile) {
          this.etapeSuivante('coordonnee');
        } else if (!tier || !captcha || !termsCheckbox) {
          this.etapeSuivante('status');
        }
      }
    },

    annuler() {
      let html = `<p class='fs-875 text-center'>Confirmez-vous l’abandon de votre formulaire d’inscription ?
        <br> Toutes les informations saisies seront perdues.</p>`;
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'oui') {
          this.$router.push({ name: 'Mon compte' });
        }
      });
    },
    showPopupCreation() {
      let html = `<p class='fs-875 text-center'>Confirmez vous l'exactitude de vos données ci-dessous ? Ces données sont nécessaires pour la finalisation de la création de votre compte.</br></br>
      Identifiant (adresse courriel nominative)</br>${
        this.email
      }</br></br>Téléphone mobile</br>${'+' + this.mobile}
      </p>`;
      this.setContent({ html, action: 'confirm-inscription' });
      this.showPopup();
      this.emitter.once('popup', async (payload) => {
        if (payload === 'oui') {
          const compte = {
            user: {
              username: this.email,
              realm: '',
              password: this.newMdp1,
              confirmPassword: this.newMdp2,
              claims: [
                {
                  uri: 'http://wso2.org/claims/lastname',
                  value: this.nom
                },
                {
                  uri: 'http://wso2.org/claims/givenname',
                  value: this.prenom
                },
                {
                  uri: 'http://wso2.org/claims/mobile',
                  value: this.mobile.replaceAll(' ', '')
                },
                {
                  uri: 'http://wso2.org/claims/tiers_declarant',
                  value: this.tier === 'oui' ? true : false
                },
                {
                  uri: 'http://wso2.org/claims/rattachement.pending',
                  value: this.rattachementPendingParam
                },
                {
                  uri: 'http://wso2.org/claims/pays',
                  value: this.pays.nom
                }
              ]
            },
            properties: [],
            captcha: {
              captchaId: this.captcha.captchaId,
              captcha: this.captchaReponse
            }
          };

          this.controlCreation = false;

          await this.$compteUtilisateurService
            .creationCompteUtilisateur(compte)
            .then((res) => {
              if (res.status === 200 && res.data && res.data.resultat) {
                this.controlCreation = true;
                setTimeout(() => {
                  const element = document.getElementById('messageCompteCree');
                  const top = element.offsetTop;
                  window.scrollTo(0, top);
                }, 200);
              } else if (
                res.status === 200 &&
                res.data &&
                !res.data.resultat &&
                res.data.infos &&
                res.data.infos.length !== 0 &&
                res.data.infos[0].infoCode === '30004'
              ) {
                this.controlCreation = false;
                this.erreur.email =
                  'Cette adresse courriel est déjà liée à un compte Portailpro.gouv. Veuillez saisir une nouvelle adresse courriel.';
                this.etapePrecedente('identifiant');
              } else if (
                res.status === 200 &&
                res.data &&
                !res.data.resultat &&
                res.data.infos &&
                res.data.infos.length != 0
              ) {
                let html = `<p class='fs-875 text-center'>${res.data.infos[0].infoMessage}</p>`;
                this.setContent({ html, action: 'info' });
                this.showPopup();
              }
            })
            .catch((err) => {
              console.error('erreur', err);
              let html = `<p class='fs-875 text-center'>Une erreur est survenue pendant le traitement.</p>`;
              this.setContent({ html, action: 'info' });
              this.showPopup();
            });
        }
      });
    },
    etapeSuivante(stepName) {
      if (stepName === 'coordonnee') {
        this.identifiantStep = false;
        this.coordonneeStep = true;
        this.statusStep = false;

        this.identifiantFilStep = true;
        this.coordonneeFilStep = true;
        this.statusFilStep = false;

        this.etapeNumber = 2;
        this.etapeName = 'Coordonnées';
        this.etapeDetail =
          'Choix des statuts et confirmation de création de compte';
      } else if (stepName === 'status') {
        this.identifiantStep = false;
        this.coordonneeStep = false;
        this.statusStep = true;

        this.identifiantFilStep = true;
        this.coordonneeFilStep = true;
        this.statusFilStep = true;

        this.etapeNumber = 3;
        this.etapeName = 'Statuts et confirmation';
        this.etapeDetail = '';
      }
    },
    etapePrecedente(stepName) {
      if (stepName === 'coordonnee') {
        this.identifiantStep = false;
        this.coordonneeStep = true;
        this.statusStep = false;

        this.identifiantFilStep = true;
        this.coordonneeFilStep = true;
        this.statusFilStep = false;

        this.etapeNumber = 2;
        this.etapeName = 'Coordonnées';
        this.etapeDetail =
          'Choix des statuts et confirmation de création de compte';
      } else if (stepName === 'identifiant') {
        this.identifiantStep = true;
        this.coordonneeStep = false;
        this.statusStep = false;

        this.identifiantFilStep = true;
        this.coordonneeFilStep = false;
        this.statusFilStep = false;

        this.etapeNumber = 1;
        this.etapeName = 'Identifiants de connexion';
        this.etapeDetail = 'Coordonnées';
      }
    },

    checkFields() {
      let control = true;
      this.erreur.adresse = '';
      this.erreur.codePostal = '';
      this.erreur.ville = '';

      if (!this.adresse.trim()) {
        this.erreur.adresse = CHAMP_OBLIGATOIRE;
        control = false;
      }
      if (!this.codePostal.trim()) {
        this.erreur.codePostal = CHAMP_OBLIGATOIRE;
        control = false;
      } else if (this.codePostal.length != 5) {
        this.erreur.codePostal = 'Le code postal n’est pas au bon format.';
        control = false;
      }
      if (!this.ville.trim()) {
        this.erreur.ville = CHAMP_OBLIGATOIRE;
        control = false;
      }

      return control;
    },

    checkCaractereNonImprimable() {
      this.adresse = this.cleanText(this.adresse);
      this.complement1 = this.cleanText(this.complement1);
      this.complement2 = this.cleanText(this.complement2);
      this.codePostal = this.cleanText(this.codePostal);
      this.ville = this.cleanText(this.ville);
    },

    cleanText(text) {
      text = text.replace(/\n\r/g, ' ');
      text = text.replace(/\p{C}/gu, '');
      text = text.replace(/ +(?= )/g, '');

      return text;
    },

    onInputFocus(iconClass, marginLeft) {
      if (this.isSafari()) {
        this.updateIconStyle(iconClass, marginLeft);
      }
    },
    onInputBlur(iconClass, marginLeft) {
      if (this.isSafari()) {
        this.updateIconStyle(iconClass, marginLeft);
      }
    },
    updateIconStyle(iconClass, marginLeft) {
      const icons = document.querySelectorAll(`.${iconClass}`);
      for (const icon of icons) {
        if (icon) {
          icon.style.marginLeft = marginLeft;
        }
      }
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },

    ...mapActions(['showPopup', 'setContent'])
  },
  mounted() {
    this.getCaptchaImage();
    // Récupération des infos de l'utilisateur suite à la redirection (enrolement DGDIP, URSSAF, DGDDI...)

    if (this.$route.query) {
      const nomParam = this.$route.query['http://wso2.org/claims/lastname'];
      this.nom = nomParam ? nomParam : '';

      const prenomParam = this.$route.query['http://wso2.org/claims/givenname'];
      this.prenom = prenomParam ? prenomParam : '';

      //const genre = this.$route.query['http://wso2.org/claims/gender'];

      const mailParam = this.$route.query[
        'http://wso2.org/claims/emailaddress'
      ];
      this.email = mailParam ? mailParam : '';

      // substring car on reçoit telephone=0602030405 et on a besoin que de 9 chiffres
      const mob = this.$route.query['http://wso2.org/claims/mobile'];
      this.mobile = mob && mob.length > 9 ? mob.substring(1) : mob;

      // substring car on reçoit telephone=0102030405 et on a besoin que de 9 chiffres
      const tel = this.$route.query['http://wso2.org/claims/telephone'];
      this.telephone = tel && tel.length > 9 ? tel.substring(1) : tel;

      /*const dirigeantParam = this.$route.query[
        'http://wso2.org/claims/dirigeant_etp"'
      ];

      this.dirigeant = dirigeantParam ? 'oui' : 'non';

      const tiersParam = this.$route.query[
        'http://wso2.org/claims/tiers_declarant"'
      ];

      this.tier = tiersParam ? 'oui' : 'non';
      */
      this.rattachementPendingParam = this.$route.query[
        'http://wso2.org/claims/rattachement.pending'
      ];
    }
    this.emitter.emit(EVENT_ACCES_RAPIDE, this.$route.name);
  },
  computed: {}
};
</script>

<style scoped>
.soulignement {
  text-decoration: underline !important;
  text-underline-offset: 5px;
}

.soulignement:hover {
  text-decoration: underline 1.5px !important;
}
</style>

<style lang="scss">
.steps-flow {
  width: 100%;
  border-right: unset;
  display: flex !important;
  margin-left: 4rem;
}

.etape-description {
  margin-left: 4rem;
}

.etape-description-detail {
  margin-left: 4.5rem;
}

.etape-number {
  color: #6a6a6a;
}

.etape-name {
  font-size: 20px;
}

.etape-precedente {
  margin-left: 4.5rem;
}

.link-precedent {
  cursor: pointer !important;
  font-size: 15px !important;
}

.step1,
.step2,
.step3 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.stepper-vertical {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  border-left: 16.313rem solid #eee;
  height: 0.375rem;
}

.stepper-vertical-terminer {
  border-left: 16.313rem solid $base-color !important;
}

.adresse-div {
  width: 83.5%;
}

.fr-input {
  box-shadow: inset 0 -2px 0 0 #6a6a6a;
}

.champs-obligatoire {
  font-size: 13px;
  color: var(--g600);
}

fieldset:not(.fieldset-visually-hidden) {
  border: none;
}
.ui.form .fields {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px;
  flex: 0 1 auto;
}

main .ui.form .username.field {
  display: flex;
  width: 100%;
}

.bloc-mail {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px;
  flex: 0 1 auto;
}

.bloc-tel {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px;
  flex: 0 1 auto;
}

main .ui.form .field {
  clear: both;
  margin: 0 0 60px;
}

.champs-obligatoires {
  width: 177px;
  height: 18px;
  margin: 7px 6px 0 0;
  font-family: Marianne;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6a6a;
}

.fields > img {
  opacity: 0.3;
}
.icon-form {
  max-height: 24px;
  max-width: 24px;
  margin-right: 30px;
  margin-left: 22px;
}

.icon-form {
  margin-top: 27px;
}

.tier-icon {
  margin-top: 45px;
}

img {
  border-style: none;
}

.ui.container {
  margin-left: 42px !important;
}
.ui.container {
  width: 1127px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.ui.container {
  display: block;
  max-width: 100% !important;
}

.ui .form input[type='text'],
.ui .form input[type='password'] {
  width: 388px;
  height: 40px;
  margin: 0px 0px 0px 0px;
  padding: 8px 16px 8px 16px;
  border-radius: 0.25rem 0.25rem 0px 0px !important;
  background-color: #f0f0f0;
  border-bottom: solid 2px #6a6a6a;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.ui .form input[type='tel'] {
  width: 340px;
  height: 40px;
  margin: 0px 20px 0px 0px;
  padding: 8px 16px 8px 16px;
  border-radius: 0.25rem 0.25rem 0px 0px !important;
  background-color: #f0f0f0;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.ui.form .two.fields > .field,
.ui.form .two.fields > .fields {
  width: 43%;
}
.ui.form .fields > .field {
  padding-right: 33px;
}
.ui.form .fields > .field {
  flex: 0 1 auto;
  padding-right: 0.5em;
}
.ui.form .fields .field {
  clear: both;
  margin: 0;
}

.entete {
  padding-left: 10%;
}

.segment-form {
  padding-left: 13%;
}

main .ui.form .username.field {
  display: flex;
  width: 100%;
}
main .ui.form .field {
  clear: both;
  margin: 0 0 60px;
}

.field > img {
  opacity: 0.3;
}
.icon-form {
  max-height: 24px;
  max-width: 24px;
  margin-right: 30px;
  margin-left: 22px;
}

.user {
  width: 24px;
}

.icon-form {
  margin-top: 27px;
}

.tier-icon {
  margin-top: 45px;
}

.logo-valide {
  opacity: 1 !important;
}

main .ui.form .username.field {
  display: flex;
  width: 100%;
}
.ui.form .field:last-child,
.ui.form .fields:last-child .field {
  margin-bottom: 0;
  height: auto;
}
main .ui.form .field {
  clear: both;
  margin: 0 0 60px;
  margin-bottom: 60px;
}
.username .required.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 64px;
}

.ui .form input[type='text'],
.ui .form input[type='password'] {
  width: 388px;
  height: 40px;
  margin: 0px 0px 0px 0px;
  padding: 8px 16px 8px 16px;
  border-radius: 0.25rem 0.25rem 0px 0px !important;
  background-color: #f0f0f0;
  border-bottom: solid 2px #6a6a6a;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

main .ui.form .username.field {
  display: flex;
  width: 100%;
}
.ui.form .field:last-child,
.ui.form .fields:last-child .field {
  margin-bottom: 0;
}
main .ui.form .field {
  clear: both;
  margin: 0 0 60px;
  margin-bottom: 30px;
}
.username .required.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 64px;
}
.ui.form .field {
  clear: both;
  margin: 0 0 1em;
}

.switch-bloc {
  display: flex;
  flex-direction: column;
  padding-right: 87px;
}

.switch-claim {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.tier.required.field {
  margin-bottom: 10px !important;
  flex-direction: row;
}

.fr-fieldset {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
}

.ri-alert-line {
  margin-top: 00.5rem;
  width: 357px;
}

.fr-fieldset--inline .fr-fieldset__content {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -0.75rem 0;
}

.fr-fieldset__content2 {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -0.75rem 0;
  margin-top: 0;
}

.fr-radio-group input[type='radio'] {
  position: absolute;
  opacity: 0;
}
.fr-radio-group {
  margin-top: 0;
  margin-right: 1rem;
}
input[type='checkbox']:enabled,
input[type='checkbox']:enabled + label,
input[type='radio']:enabled,
input[type='radio']:enabled + label {
  cursor: pointer;
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding: 6px;
  border: solid 1px #1e1e1e;
  background-color: #ffffff;
  margin-left: 0.5em;
  background-clip: content-box;
}

.terms-privacy {
  margin-left: 76px;
}

.message-personal-data {
  margin: 0 0 -5% 0;
  padding: 8% 0 0 76px;
}

.fr-checkbox-group:last-child {
  margin-bottom: -0.75rem;
}
.fr-checkbox-group:first-child {
  margin-top: -0.75rem;
}
.fr-checkbox-group input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}
.fr-checkbox-group input[type='checkbox'] + label {
  position: relative;
  padding: 0.4rem 0;
  display: block;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.pr-btn-valider {
  background: $base-color;
  color: white;
  border: 0;
  margin-top: 1rem;
  font-size: 1.25rem;
  min-height: 3.6rem;
  padding: 0.75rem 2rem;
  line-height: 2rem;
  margin-right: 1rem;
  margin-left: 0;
  margin: 1rem;
}

.pr-btn-valider:hover {
  background-color: #1212ff !important;
}

.control-label {
  font-size: 14px;
}

.pr-btn-annuler {
  background: white;
  color: $base-color;
  border: 1px solid $base-color !important;
  margin-top: 1rem;
  width: 9.1rem;
  font-size: 1.25rem;
  line-height: 2rem;
  min-height: 3.6rem;
  padding: 0.75rem 2rem;
  margin: 1rem;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 4rem;
}

.fr-fi-information-line::before {
  width: 1rem;
  height: 1.5rem;
}

.deque-tooltip-wrapper {
  font-size: 1rem;
}

.ri-close-line {
  color: #9f3a38;
}

.ri-check-line {
  color: green;
}
.text-info {
  color: #6a6a6a !important;
  font-size: 12px;
  font-style: italic;
}
.termsCheckbox {
  display: block !important;
}

.form-newmdp {
  display: grid;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.input-eye {
  width: 19px;
  height: 16px;
  position: initial;
  margin-left: 22rem;
  margin-top: -30px;
  color: $base-color;
  z-index: 3400;
}

.input-eye-2 {
  width: 19px;
  height: 16px;
  position: initial;
  margin-left: -2rem;
  margin-top: -5px;
  color: $base-color;
  z-index: 3400;
}

.captcha-btn {
  cursor: pointer;
}

.ri-error-warning-line {
  margin-top: 0.2rem;
  margin-right: 0.5rem;
}

.input-error-border {
  border-color: currentcolor currentcolor rgb(201, 25, 30) !important;
  box-shadow: rgb(201, 25, 30) 0px 0px 0px 0px inset !important;
}

.form-control {
  color: rgb(0, 0, 0);
}
.form-control:focus {
  color: rgb(0, 0, 0);
}

.container {
  padding: 3%;
  padding-right: 2%;
  margin: 2%;
}

.btn:hover {
  background-color: $base-color !important;
  color: white;
}

.form-button {
  display: flex;
  width: 35%;
  padding-top: 0.5rem;
  height: 3.5rem;
  margin-left: 40%;
}

.btn {
  background: $base-color;
  color: white !important;
  font-size: 14px;
  border: 0;
  width: 100%;
  margin-left: 1rem;
}

.btn:hover {
  background-color: $base-color;
}

@media only screen and (max-width: 760px) {
  .container {
    width: 100%;
    padding: 0%;
    margin: 0%;
  }

  .entete {
    padding-left: 5%;
  }

  .etape-description {
    margin-left: 5%;
  }

  .steps-flow {
    margin-left: 5%;
  }

  .etape-description-detail {
    margin-left: 7%;
  }

  .icon-form {
    margin-right: 4px;
    margin-left: 4px;
  }

  .ui .form input[type='text'],
  .ui .form input[type='password'],
  .ui.form .fields {
    width: 100%;
    display: block;
  }

  .icon-form {
    display: none;
  }

  .ui.form .two.fields > .field,
  .ui.form .two.fields > .fields {
    width: 100%;
  }

  .form-newmdp {
    display: block;
  }

  .ui.form .fields > .field {
    padding-right: 0;
  }

  .input-eye {
    position: absolute;
    margin-left: 20rem;
    margin-top: -30px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-right: 0;
  }

  .step2 {
    display: none !important;
  }

  .step3 {
    display: none !important;
  }

  .iti {
    width: 100%;
  }

  .adresse-div {
    width: 93%;
  }

  .switch-bloc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .switch-claim {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .fr-radio-group {
    margin-top: 0;
    margin-right: 4rem;
  }

  .mr-2 {
    margin-right: 0 !important;
  }

  .terms-privacy {
    margin-left: 35px;
  }

  .message-personal-data {
    margin-bottom: 0;
    padding-bottom: 8%;
    padding-left: 0;
  }
  .message-personal-data p {
    font-size: 14px;
    line-height: 1.5;
  }

  .termsCheckbox {
    display: contents !important;
  }

  .fr-checkbox-group input[type='checkbox'] {
    margin-left: -14%;
  }
}
</style>
